import {
  Avatar,
  Container,
  Content,
  Frame,
  Heading,
  Header,
  Stack,
  Text,
} from '@kinesis/bungle';
// eslint-disable-next-line max-len
import { CustomerTermsAndConditionsItem } from '@/components/customer-terms-and-conditions/customer-terms-and-conditions-item';

import { useTermsId } from '@/hooks/use-terms-id';
import { useGetTermsQuery } from '@/api/terms';
import { Loading } from '@/components/loading';
import { Layout } from '@/components/layout';
import { formatDateString } from '@/date';

const TermsReview = () => {
  const termsId = useTermsId();
  const { data: terms, isLoading } = useGetTermsQuery(termsId);

  if (isLoading) {
    return <Loading />;
  } else if (!terms) {
    return null;
  } else {
    return (
      <Container direction='horizontal'>
        <Header border shadow>
          <Content
            alignX='center'
            background='gray'
            padding='medium'
            paddingMode='equal'
          >
            <Content maxWidth={648} textAlign='center'>
              <Stack alignX='center' space='small'>
                <Avatar
                  alt={`${terms.ownerName} logo`}
                  image={terms.ownerAvatarUrl}
                  magnitude='xlarge'
                  variant='organisation'
                />
                <Heading as='h1' size='large'>
                  {terms.ownerName} data terms of use
                </Heading>
                <Text>
                  You are reviewing digital terms for data licensed by{' '}
                  {terms.ownerName}.
                </Text>
                <Text tone='secondary'>
                  Version: {formatDateString(terms.createdAt)}
                </Text>
              </Stack>
            </Content>
          </Content>
        </Header>
        <Frame>
          <Layout variant='narrow'>
            <Content padding='small'>
              <CustomerTermsAndConditionsItem
                id={terms.id}
                name={terms.name}
                text={terms.text}
              />
            </Content>
          </Layout>
        </Frame>
      </Container>
    );
  }
};

export { TermsReview };
