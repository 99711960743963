import { useParams } from 'react-router-dom';

type TermsUrl = {
  termsId: string;
};

const useTermsId = () => {
  const params = useParams<TermsUrl>();
  return Number(params.termsId);
};

export { useTermsId };
